import {
  MantineProvider,
  ColorSchemeScript,
  useMantineTheme,
  Text,
  Button,
  Stack,
  Drawer,
  Loader,
  Center,
} from '@mantine/core';
import '@mantine/dropzone/styles.css';
import { Notifications } from '@mantine/notifications';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
  useRouteError,
  useNavigation,
  useLocation,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '../style/fonts.css';
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { json } from '@remix-run/node';

import { getAnonymousId } from '../services/analytics.server';
import analytics from '../services/analytics.client';
import cssResolver from '@/app/ProTheme/cssResolver';
import ProTheme from './ProTheme';
import { UnsavedChangesProvider } from '@/components/Providers/UnsavedChangesProvider';
import { UnsavedChangesModal } from '@/components/Modals/UnsavedChangesModal';
import { useSearchNotificationFlash } from '@/hooks/useSearchNotificationFlash';
import { useEffect } from 'react';
import * as gtag from './gtag.client';

export const meta: MetaFunction = () => {
  return [
    { title: 'DeepCast Pro for Podcasters' },
    {
      name: 'description',
      content:
        'DeepCast Pro: The comprehensive toolkit for podcasters to amplify their reach, engage audiences, and monetize content. Harness AI-powered insights, streamline post-production, and unlock new growth opportunities. Join the evolution of podcast management with DeepCast Pro - where creators become community builders',
    },
    { property: 'og:image', content: '/images/og-image.png' },
    { property: 'og:image:type', content: 'image/png' },
    { property: 'og:image:width', content: '1200' },
    { property: 'og:image:height', content: '630' },
  ];
};

interface EnvironmentData {
  GOOGLE_TAG_ID?: string;
  RUDDERSTACK_DATA_PLANE_URL?: string;
  RUDDERSTACK_WRITE_KEY?: string;
  SENTRY_ENVIRONMENT?: string;
}

interface Data {
  ENV: EnvironmentData;
  anonymousId: string;
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return json({
    anonymousId: getAnonymousId(request),
    ENV: {
      DEEPCAST_FM_URL: process.env.DEEPCAST_FM_URL,
      GOOGLE_TAG_ID: process.env.GOOGLE_TAG_ID,
      RUDDERSTACK_DATA_PLANE_URL: process.env.RUDDERSTACK_DATA_PLANE_URL,
      RUDDERSTACK_WRITE_KEY: process.env.RUDDERSTACK_WRITE_KEY,
      SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
    },
  });
};

export function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigation = useNavigation();
  const data = useRouteLoaderData<Data>('root');
  try {
    analytics(data?.anonymousId, data?.ENV.RUDDERSTACK_WRITE_KEY, data?.ENV.RUDDERSTACK_DATA_PLANE_URL)?.page();
  } catch {
    // error intentionally ignored
  }

  useSearchNotificationFlash();

  const googleTagId = data?.ENV?.GOOGLE_TAG_ID;

  useEffect(() => {
    if (googleTagId?.length) {
      gtag.pageview(location.pathname, googleTagId);
    }
  }, [location, googleTagId]);

  return (
    <html lang="en">
      <head>
        {googleTagId && (
          <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleTagId}`} />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${googleTagId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon.png" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body>
        <MantineProvider theme={ProTheme} cssVariablesResolver={cssResolver} defaultColorScheme="dark">
          <UnsavedChangesProvider>
            {children}
            <Notifications autoClose={10000} position="top-center" m="xl" lh="xl" zIndex={1000} />
            <Drawer
              shadow="0%"
              position="top"
              opened={navigation.state != 'idle'}
              size={50}
              onClose={() => {}}
              withCloseButton={false}
              withOverlay={false}
              styles={{
                content: {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Center>
                <Loader size="sm" type="dots" />
              </Center>
            </Drawer>
            <UnsavedChangesModal />
          </UnsavedChangesProvider>
        </MantineProvider>
        <ScrollRestoration />
        {data && data.ENV && (
          <script dangerouslySetInnerHTML={{ __html: `window.ENV = ${JSON.stringify(data?.ENV)}` }} />
        )}
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  const theme = useMantineTheme();
  captureRemixErrorBoundaryError(error);

  if (import.meta.env.DEV) {
    return (
      <div style={{ color: 'white', margin: '5em' }}>
        <h1>Development Mode Error</h1>
        <pre>{new String(error)}</pre>
      </div>
    );
  }

  return (
    <Stack
      px="1rem"
      gap="lg"
      align="center"
      justify="center"
      ta="center"
      h="100%"
      mih="100vh"
      bg={theme.other.colors.deepDark}
    >
      <Text size="3rem" fw="bold" c="white">
        Oops! Looks like our mic is broken
      </Text>
      <Text size="lg" lh={1.2}>
        Stay tuned while we figure out what went wrong...
      </Text>
      <Button component="a" href="/" size="md">
        Return to DeepCast Pro homepage
      </Button>
    </Stack>
  );
};

export default function App() {
  return <Outlet />;
}
