import { RudderAnalytics } from '@rudderstack/analytics-js';
import * as Sentry from '@sentry/remix';

let instance: RudderAnalytics;

export default function analytics(
  anonymousId?: string,
  writeKey?: string,
  dataPlaneUrl?: string,
): RudderAnalytics | undefined {
  if (instance) {
    return instance;
  }

  try {
    instance = new RudderAnalytics();

    if (writeKey && dataPlaneUrl) {
      instance.load('2lqQ0TpWFpLCiJibkm6lp5rNu0v', 'https://deepcastkkzbwm.dataplane.rudderstack.com');
    }
    if (anonymousId) {
      try {
        instance.setAnonymousId(anonymousId);
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    return instance;
  } catch (e) {
    Sentry.captureException(e);
  }
}
