import ProTheme from '@/app/ProTheme';
import { notifications } from '@mantine/notifications';
import { useSearchParams } from '@remix-run/react';
import { useEffect } from 'react';

const titleColors: { [key: string]: string } = {
  Error: ProTheme.other?.colors.dangerRed || 'red',
  Information: ProTheme.primaryColor || 'blue',
  Warning: 'orange',
};

/**
 * Flashes a notification when the notification search param is present in the current URL.
 * @param key the search param to use. Defaults to 'notification'
 */
export function useSearchNotificationFlash(key: string = 'notification') {
  const [searchParams, setSearchParams] = useSearchParams();

  const notification = searchParams.get(key);

  useEffect(() => {
    if (!notification) {
      return;
    }

    const [title, message] = notification.split('|', 2);
    if (!message) {
      return;
    }

    const color = titleColors[title];

    notifications.show({
      color,
      id: notification,
      message,
      title,
    });
    searchParams.delete(key);
    setSearchParams(searchParams);
  }, [notification, key, searchParams, setSearchParams]);
}
