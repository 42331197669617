import { Text, Stack, Flex, Modal, Button } from '@mantine/core';

import { useUnsavedChanges } from '@/components/Providers/UnsavedChangesProvider';

export const UnsavedChangesModal = () => {
  const { isModalOpen, handleDiscardChanges, handleKeepEditing } = useUnsavedChanges();

  return (
    <Modal opened={isModalOpen} onClose={handleKeepEditing} withCloseButton={false}>
      <Stack gap="sm">
        <Text ta="center" lh={1.2}>
          You have unsaved changes.
          <br />
          Continue anyway?
        </Text>
        <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" gap="sm">
          <Button onClick={handleDiscardChanges} variant="danger" w="100%">
            Discard Changes
          </Button>
          <Button onClick={handleKeepEditing} w="100%">
            Keep Editing
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};
