import type { CSSVariablesResolver } from '@mantine/core';

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--border-blue': theme.other.colors.borderBlue,
    '--deep-dark': theme.other.colors.deepDark,
    '--deep-ocean': theme.other.brandColors.deepOcean,
    '--deep-teal': theme.other.brandColors.deepTeal,
    '--magenta': theme.other.brandColors.magenta,
    '--mantine-color-border-gray': theme.other.colors.borderGray,
    '--mantine-color-danger-red': theme.other.colors.dangerRed,
    '--mantine-color-icon-gray': theme.other.colors.iconGray,
    '--rich-black': theme.other.colors.richBlack,
    '--rich-black-50': theme.other.colors.richBlack50,
    '--sea': theme.other.brandColors.sea,
    '--sunray': theme.other.brandColors.sunray,
    '--sunray-20': theme.other.colors.sunray20,
    '--sunlight': theme.other.brandColors.sunlight,
    '--surf': theme.other.brandColors.surf,
    '--shallows': theme.other.brandColors.shallows,
    '--sea-spray': theme.other.brandColors.seaSpray,
    '--white-40': theme.other.colors.white40,
    '--white-60': theme.other.colors.white60,
  },
  light: {},
  dark: {},
});

export default resolver;
